import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Slider from './Components/Slider.js';
import Content from './Components/Content.jsx';
import AboutUs from './Components/Aboutus.jsx';
import Navbar from './Components/Navbar.js';
import ScrollToTop from './Components/ScrollToTop.jsx';
import Footer from './Components/Footer.jsx';
import Swipe from './Components/SwipeableCards.jsx';
import WhyDzyris from './Components/WhyDzyris.jsx';
function App() {
  return (
    <Router>
      <div className="App">
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<><Slider /><Swipe/><Content /><AboutUs /><WhyDzyris /></>} />
          {/* Add other routes here as needed */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
