import React from 'react';
import './WhyDzyris.css'; // Import custom CSS for styling and transitions

// Sample images for demonstration
import img1 from './images/dzyrisinfotech.png';
import img2 from './images/firangiburger.png';
import img3 from './images/How-do-I-check-if-a-company-is-in-MCA.jpg';
import img4 from './images/frozenfood.jpeg';
import img5 from './images/dzpharma.png';

const images = [img1, img2, img3, img4];

const WhyDzyris = () => {
  return (
    <div className="why-us-container" id="why-dzyris">
      <section className="why-us-content">
        <h1>Why Choose Us?</h1>
        <p style={{color: "DarkSlateGray"}}>
          We provide exceptional services with a focus on quality, innovation, and customer satisfaction. 
          Here are some reasons why we stand out:
        </p>
        <ul>
          <li><span className="highlight">Expert Team:</span> Our team consists of highly skilled professionals.</li>
          <li><span className="highlight">Innovative Solutions:</span> We use cutting-edge technology to solve problems.</li>
          <li><span className="highlight">Customer Focused:</span> Our solutions are tailored to meet your specific needs.</li>
          <li><span className="highlight">High Quality:</span> We ensure high standards in every aspect of our service.</li>
          <li><span className="highlight">Reliable Support:</span> Our support team is available to assist you 24/7.</li>
        </ul>
      </section>
    </div>
  );
};

export default WhyDzyris;
