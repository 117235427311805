import React from 'react';
import { useSwipeable } from 'react-swipeable';
import './Swipe.css';
import img1 from './images/WhatsApp Image 2024-08-10 at 3.28.07 PM.jpeg';
import img2 from './images/officeog.jpeg';
import img3 from './images/officeog2.jpeg';
import Modal from './Modal';

// Sample card data
const cards = [
  { image: img1 },
  { image: img2 },
  { image: img3 },
  { image: img1 },
  { image: img2 },
  { image: img3 },
];

const SwipeableCards = () => {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState('');

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrevious(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const handleNext = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = Math.min(prevIndex + 1, cards.length - 1);
      document.querySelector('.cards-wrapper').classList.add('transition');
      return newIndex;
    });
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = Math.max(prevIndex - 1, 0);
      document.querySelector('.cards-wrapper').classList.add('transition');
      return newIndex;
    });
  };

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      document.querySelector('.cards-wrapper').classList.remove('transition');
    }, 300); // Duration should match the CSS transition duration
    return () => clearTimeout(timeout);
  }, [currentIndex]);

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage('');
  };

  return (
    <div className="swipeable-container">
      <h1 style={{marginTop:"1%"}}>Events</h1>
      <button className="arrow-button left-arrow" onClick={handlePrevious}>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.293 7.293a1 1 0 0 1 1.414 1.414L6.414 12l3.293 3.293a1 1 0 0 1-1.414 1.414l-4-4a1 1 0 0 1 0-1.414l4-4z" />
        </svg>
      </button>
      <div className="cards-wrapper" {...handlers} style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {cards.map((card, index) => (
          <div className="card" key={index}>
            <img
              src={card.image}
              alt={`Card ${index}`}
              className="card-image"
              onClick={() => openModal(card.image)}
            />
            <div className="card-content">
              <h3 className="card-title">{card.title}</h3>
              <p className="card-description">{card.description}</p>
            </div>
          </div>
        ))}
      </div>
      <button className="arrow-button right-arrow" onClick={handleNext}>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.707 16.707a1 1 0 0 1-1.414-1.414L17.586 12l-3.293-3.293a1 1 0 1 1 1.414-1.414l4 4a1 1 0 0 1 0 1.414l-4 4z" />
        </svg>
      </button>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        imageSrc={selectedImage}
        altText=""
      />
    </div>
  );
};

export default SwipeableCards;
