import React, { useRef } from 'react';
import { Carousel, Row, Col } from 'antd';
import CountUp from 'react-countup';

import myImage1 from './images/group-of-company-img.jpeg';
import myImage2 from './images/dzyrisff.png';
import myImage3 from './images/dzyrisfir.png';
import myImage4 from './images/dzyriscarit.png';

import './Slider.css'; // Import custom CSS if needed

const NumberCounter = ({ end, duration = 2, prefix = '', suffix = '', className = '' }) => (
  <div className={`number-counter ${className}`}>
    <CountUp
      end={end}
      duration={duration}
      prefix={prefix}
      suffix={suffix}
      separator=","
      delay={0}
    />
  </div>
);

const Slider = () => {
  const carouselRef = useRef(null);

  return (
    <div className="carousel-container">
      <Carousel autoplay ref={carouselRef} dots={true} className="custom-carousel">
        <div className="carousel-slide">
          <img src={myImage1} alt="Slide 1" className="carousel-image" />
          <div className="carousel-overlay">
            <h3 className="carhead">Dzyris Group of Companies</h3>
            <Row gutter={16} justify="center" style={{ marginTop: '20px' }}>
              <Col span={8} style={{ textAlign: 'center' }}>
                <NumberCounter end={10000} prefix="+" />
                <p className='countfoot'>Happy Customers</p>
              </Col>
              <Col span={8} style={{ textAlign: 'center' }}>
                <NumberCounter end={500} prefix="+" />
                <p className='countfoot'>Expert Employees</p>
              </Col>
              <Col span={8} style={{ textAlign: 'center' }}>
                <NumberCounter end={50} prefix="+" />
                <p className='countfoot'>Years in Business</p>
              </Col>
            </Row>
          </div>
        </div>
        <div className="carousel-slide">
          <img src={myImage4} alt="Slide 4" className="carousel-image" />
          <div className="carousel-overlay">
            {/* Add content here if needed */}
          </div>
          
        </div>
        <div className="carousel-slide">
          <img src={myImage2} alt="Slide 4" className="carousel-image" />
          <div className="carousel-overlay">
            {/* Add content here if needed */}
          </div>
          
          
        </div>
        <div className="carousel-slide">
          <img src={myImage3} alt="Slide 4" className="carousel-image" />
          <div className="carousel-overlay">
            {/* Add content here if needed */}
          </div>
          
          
        </div>
      </Carousel>
    </div>
  );
};

export default Slider;




